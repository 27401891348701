.modelSection-edit {
    display: flex;
    gap: 1rem;
  }
  
  .modelPreviewContainer-edit {
    width: 50%;
    height: 300px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .modelPreview {
    flex-grow: 1;
  }
  
  .lightingControls-edit {
    margin-top: 0.5rem;
  }
  
  .modelUploadSection-edit {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .fileUpload-edit {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .fileUpload-edit.active {
    border-color: #007bff;
  }
  
  .uploadIcon-edit {
    width: 48px;
    height: 48px;
    margin-bottom: 0.5rem;
  }
  
  /* Aggiungi questo per gestire il testo che potrebbe andare fuori dall'area di caricamento */
  .fileUpload-edit p {
    word-break: break-word;
    font-size: 0.9em;
  }