/* Evita lo scrolling orizzontale */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f9fa;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

.content {
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  align-self: flex-start;
}

.content h2 {
  margin-top: 1rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #333;
}

.categoryContainer,
.subCategoryContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1rem;
}


.addDishButton {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #a90d00;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 15px 30px;
  font-size: 18px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 1000;
}

.addDishButton:hover {
  background-color: #8c0b00;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.addDishButton:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #a90d00;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.deleteConfirmationModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.deleteConfirmationModal button {
  margin: 0 10px;
  padding: 5px 10px;
  cursor: pointer;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-modal {
  background-color: white;
  padding: 20px;
  border-radius: 30px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.cancel-button, .delete-button {
  padding: 20px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-weight: bold;
}

.cancel-button {
  background-color: #f0f0f0;
  color: #333;
}

.delete-button {
  background-color: #a90d00;
  color: white;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.categoryButtonWrapper {
  user-select: none;
}

.categoryButtonWrapper:active {
  cursor: grabbing;
}