.edit-category-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .edit-category-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 30px;
    width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .edit-category-modal-content h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .edit-category-modal-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 30px;
    font-size: 16px;
  }
  
  .edit-category-modal-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .edit-category-modal-cancel,
  .edit-category-modal-apply {
    padding: 20px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .edit-category-modal-cancel {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .edit-category-modal-apply {
    background-color: #a90d00;
    color: white;
  }
  
  .edit-category-modal-apply:disabled {
    background-color: #ffcccb;
    cursor: not-allowed;
  }
  
  .edit-category-modal-error {
    color: red;
    margin-bottom: 10px;
  }