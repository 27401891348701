.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: rgb(169, 13, 0);
    padding: 1rem;
  }
  
  .login-box {
    background: white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 500px;
    width: 80%;
  }
  
  .logoLogin {
    width: 30%;
    margin-bottom: 2rem;
  }
  
  .titleLogin {
    color: rgb(169, 13, 0);
    font-size: 2rem;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 800;
  }
  
  .subtitleLogin {
    color: rgb(169, 13, 0);
    margin-bottom: 3rem;
    margin-top: 2rem;
    font-size: 1rem;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
  }
  
  .input {
    width: 80%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 20px;
    font-size: 1rem;
    font-family: "Plus Jakarta Sans", sans-serif;
    text-align: left;
  }
  
  .button {
    width: 80%;
    padding: 1rem;
    border: none;
    margin-top: 1rem;
    border-radius: 20px;
    background: rgb(169, 13, 0);
    color: white;
    font-size: 1rem;
    cursor: pointer;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
  }
  
  .button:hover {
    background: #cc0000;
  }
  
  .forgot-password {
    display: block;
    margin-top: 1rem;
    color: #007bff;
    cursor: pointer;
    text-decoration: none;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 400;
  }
  
  .forgot-password:hover {
    text-decoration: underline;
  }
  
  .error-message {
    color: red;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 400;
  }
  
  .loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: rgb(169, 13, 0);
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  @media (max-width: 768px) {
    .login-box {
      padding: 1.5rem;
      max-width: 90%;
    }
  
    .logo {
      width: 70%;
    }
  
    .title {
      font-size: 1.5rem;
    }
  
    .subtitle {
      font-size: 0.875rem;
    }
  
    .input, .button {
      width: 100%;
    }
  }