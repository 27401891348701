.categoryButton,
.subCategoryButton {
  padding: 0.5rem 1.5rem;
  border: 2px solid #878787;
  border-radius: 20px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  min-width: 120px;
  text-align: center;
  background-color: transparent;
  color: #666;
}

.categoryButton:hover,
.subCategoryButton:hover,
.categoryButton.active,
.subCategoryButton.active {
  background-color: #a90d00;
  color: white;
  border: 2px solid #a90d00;
}

.addButton {
  border: 2px dashed #a90d00;
  background-color: transparent;
  color: #a90d00;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  cursor: pointer;
  min-width: 120px;
  text-align: left;
}

.addButton:hover {
  background-color: rgba(169, 13, 0, 0.1);
}

.addButton::after {
  content: "+";
  font-size: 1.2em;
  margin-left: 0.5rem;
}

.categoryButtonWrapper {
  position: relative;
  display: inline-block;
}

.deleteIcon {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #a90d00;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;
}

.deleteIcon:hover {
  background-color: #800a00;
}