.dishesContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
    width: 100%;
  }
  
  .dishCard {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 300px;
    padding: 20px;
  }
  
  .dishCard:hover {
    transform: translateY(-5px);
  }
  
  .dishImageContainer {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 15px;
  }
  
  .dishImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .dishCard h3 {
    margin-top: 5%;
    font-size: 1rem;
    text-align: center;
  }
  

  .dishCard {
    position: relative;
  }
  
  .dishActions {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    padding: 0 30px;
  }
  
  .actionButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    padding: 5px;
    transition: color 0.3s ease;
  }
  
  .editButton:hover {
    color: #4a90e2;
  }
  
  .visibilityButton:hover {
    color: #f39c12;
  }
  
  .deleteButton:hover {
    color: #e74c3c;
  }