.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Cambiato da center a flex-start */
  overflow-y: auto; /* Aggiunto per permettere lo scroll */
  padding: 20px 0; /* Aggiunto per dare un po' di spazio sopra e sotto */
}

.modalContent {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh; /* Limitare l'altezza al 90% della viewport */
  overflow-y: auto; /* Permette lo scroll interno se il contenuto supera max-height */
  margin: auto; /* Centra verticalmente se c'è spazio */
}
  
  .modalContent h2 {
    margin-top: 0;
    font-family: "Plus Jakarta Sans", sans-serif;
    margin-bottom: 1.5rem;
  }
  
  .inputGroup {
    display: flex;
    gap: 1rem;
  }
  
  .inputGroup input[type="text"] {
    flex: 3;
  }
  
  .inputGroup input[type="number"] {
    flex: 1;
    position: relative;
  }
  
  .inputGroup input[type="number"]::after {
    content: '€';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .fileUpload {
    border: 2px dashed #ccc;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    transition: border 0.3s ease;
    margin-bottom: 20px;
    padding-bottom: 10px;
    flex: 1;
  }
  
  .fileUpload.active {
    border-color: #a90d00;
  }
  
  .fileUpload p {
    margin: 10px 0 0;
  }
  
  .uploadIcon {
    width: 30%;
    height: 30%;
  }
  
  .modalActions {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .cancelButton, .submitButton {
    border: none;
    border-radius: 20px;
    padding: 1.5rem 2rem;
    cursor: pointer;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: bold;
    width: 40%;
  }
  
  .cancelButton {
    background: #f0f0f0;
    color: #333;
  }
  
  .submitButton {
    background: #a90d00;
    color: white;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-family: "Plus Jakarta Sans", sans-serif;
    box-sizing: border-box;
  }
  
  textarea {
    min-height: 100px;
    resize: vertical;
  }


.modalContent input[type="text"],
.modalContent input[type="number"],
.modalContent input[type="file"],
.modalContent textarea,
.modalContent select {
  border-radius: 20px;  /* Questo rende gli angoli arrotondati */
  padding: 10px 15px;   /* Aumenta leggermente il padding per un aspetto migliore */
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

/* Stile specifico per il pulsante di caricamento file */
.modalContent .fileUpload {
  border-radius: 20px;
  overflow: hidden;  /* Assicura che il contenuto non fuoriesca dai bordi arrotondati */
  height: -webkit-fill-available;
}

.modalContent .fileUpload label {
  border-radius: 20px;
}

/* Stile per i pulsanti */
.modalContent button {
  border-radius: 20px;
}


.modelSection {
  display: flex;
  gap: 20px;
}

.modelPreviewContainer {
  max-width: 400px; /* o la dimensione massima che preferisci */
  max-height: 400px;
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.brightnessControl {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brightnessControl label {
  margin-right: 10px;
}

.brightnessControl input[type="range"] {
  width: 200px;
}

select:disabled {
  background-color: #f0f0f0;
  color: #888;
  cursor: not-allowed;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loadingSpinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #a90d00;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}