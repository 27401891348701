.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 2rem;
    background: #a90d00;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  
  .logo {
    height: 70px;
    margin-right: 1rem;
  }
  
  .title {
    font-size: 1.5rem;
    color: white;
    margin: 0;
    font-family: "Plus Jakarta Sans", sans-serif;
    margin-left: 2.5rem;
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 1rem;
      margin-left: 0.2rem;
    }
  }
  
  .logoutButton {
    background: white;
    color: #a90d00;
    border: none;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1rem;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
  }
  
  .logoutButton:hover {
    background: #cc0000;
    color: white;
  }
  
  @media (max-width: 768px) {
    .logoutButton {
      padding: 0.4rem 0.8rem;
      font-size: 0.9rem;
    }
  }
  
  .restaurantInfo {
    display: flex;
    align-items: center;
  }
  